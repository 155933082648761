import { IUser } from "./users";

export enum ENotificationType {
  TENDER_CREATED = "tender-created",
  TENDER_SUBMITTED = "tender-submitted",
  SCHEDULE_OF_EXECUTION_SUBMITTED = "schedule-of-execution-submitted",
  RFP_CHANGE_ORDER_CREATED = "rfp-change-order-created",
  RFP_CHANGE_ORDER_UPDATED = "rfp-change-order-updated",
  RFP_CHANGE_ORDER_COMMENT = "rfp-change-order-comment",
  RFP_COMMENT = "rfp-comment",
}

export interface INotification {
  id: number;
  createdAt: string;
  updatedAt: string;
  relationId: number;
  relationType: string;
  type: ENotificationType;
  text: string;
  readAt: string | null;
  metadata: {
    rfpId: string;
    tenderId: string;
  };
  rfp: {
    id: number;
    projectName: string;
  };
  relatedUser: IUser;
}
